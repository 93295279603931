import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';

const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    lazyLoad: 'ondemand',
};

class ClassesSlide extends Component {
    render() {
        const {
            classesGallery,
        } = this.props;
        return (
            <>
                <section>
                    <div className="container">
                        <div className="container flexwrap aboutus_slider">
                            <div className="openplft">
                                <div
                                    id="myCarousel3"
                                    className="carousel slide"
                                    data-ride="carousel"
                                >
                                    <Slider
                                        {...settings}
                                        className="carousel-inner"
                                    >
                                        {classesGallery.map(galleryImage => (
                                            <div
                                                className="item carousel-item active"
                                                key={galleryImage.id}
                                            >
                                                <img
                                                    className="margin-0"
                                                    src={
                                                        galleryImage.localFile
                                                            .childImageSharp.fluid
                                                            .src
                                                    }
                                                    loading="lazy"
                                                    alt="title"
                                                />
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default ClassesSlide;
